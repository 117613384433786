import { type MouseEvent } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { type CustomerDataDto } from '@jsmdg/customer-backend-api-client-javascript-axios';
import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { GA4EventName, GA4FeatureCategory, trackAnalyticsEvent } from '@jsmdg/tracking';
import {
    Button,
    ButtonColor,
    ButtonShape,
    ButtonSize,
    ButtonVariant,
    ChevronIconSrc,
    CloseIconSrc,
    Image,
    Link,
    LinkVariant,
    MailOutlineIconSrc,
    ProfileIconSrc,
    PureLink,
} from '@jsmdg/yoshi';
import { getLoginUrl } from '../../helper/customerAccountUrlUtils';
import { type HeaderContext } from '../../types';
import styles from './VoucherModalHeader.module.scss';

enum UserAction {
    Login = 'login',
    Register = 'register',
}

type VoucherModalHeaderProps = {
    readonly className: string;
    readonly customerInfo?: CustomerDataDto | null;
    readonly onRequestClose: () => void;
};

const messages = defineMessages({
    customerAccount: {
        defaultMessage: 'Kundenkonto',
    },
    buttonClose: {
        defaultMessage: 'Ausfalter schließen',
    },
    login: {
        defaultMessage: 'Anmelden',
    },
    register: {
        defaultMessage: 'Neues Konto erstellen',
    },
});

const VoucherModalHeader = ({
    className,
    customerInfo,
    onRequestClose,
}: VoucherModalHeaderProps): JSX.Element | null => {
    const intl = useIntl();
    const { isLoggedIn, isMydays } = useFragmentContext<HeaderContext>();

    const onUserClick = (event: MouseEvent, action: UserAction): void => {
        event.preventDefault();
        event.stopPropagation();

        const isLoginAction = action === UserAction.Login;

        trackAnalyticsEvent({
            eventData: {
                eventName: GA4EventName.ClickNavigationItem,
                feature_category: GA4FeatureCategory.HeaderNavigation,
                click_element: `voucher uploaded pop up ${isLoginAction ? 'login' : 'sign up'}`,
                click_text: `${isLoginAction ? 'Anmelden' : 'Neues Konto erstellen'}`,
                click_url: `https://www.${isMydays ? 'mydays' : 'jochen-schweizer'}.de/login`,
            },
        });

        window.location.assign(getLoginUrl());
    };

    const onProfileClick = (event: MouseEvent): void => {
        event.preventDefault();
        trackAnalyticsEvent({
            eventData: {
                eventName: GA4EventName.ClickNavigationItem,
                feature_category: GA4FeatureCategory.HeaderNavigation,
                click_element: 'voucher uploaded pop up customer account',
                click_url: `https://www.${isMydays ? 'mydays' : 'jochen-schweizer'}.de/account`,
            },
        });

        window.location.assign(isLoggedIn ? '/account' : getLoginUrl());
    };

    return (
        <div className={className}>
            <div className="justify-content-between d-flex align-items-center pb-2x">
                <h2 className="pb-0">{intl.formatMessage(messages.customerAccount)}</h2>
                <Button
                    variant={ButtonVariant.Ghost}
                    shape={ButtonShape.Icon}
                    size={ButtonSize.Small}
                    color={ButtonColor.Dark}
                    onClick={onRequestClose}
                    a11yText={intl.formatMessage(messages.buttonClose)}
                    className={styles.closeIcon}
                >
                    <Image src={CloseIconSrc} alt="close icon" lazyLoad />
                </Button>
            </div>
            <PureLink
                href={isLoggedIn ? '/account' : '/login'}
                onClick={onProfileClick}
                data-testid="profile-link"
                internal
            >
                <div className="d-flex align-items-center">
                    <Image
                        src={ProfileIconSrc}
                        alt="profile image"
                        lazyLoad
                        className={classNames(styles.profileIcon, {
                            [styles.active]: !!customerInfo,
                        })}
                    />

                    <div className="d-flex flex-grow-1 justify-content-between ml-2x">
                        <div className="d-flex flex-1 flex-column ml-1-5x py-0-5">
                            {!!customerInfo && (
                                <h3 className="fw-bold pb-0">
                                    {customerInfo.firstName} {customerInfo.lastName}
                                </h3>
                            )}
                            {!customerInfo && (
                                <Link
                                    href="/login"
                                    variant={LinkVariant.Grey}
                                    internal
                                    className={classNames(styles.link, 'font-brand mb-1x')}
                                    onClick={(event: MouseEvent) =>
                                        onUserClick(event, UserAction.Login)
                                    }
                                    dataTestId="login-link"
                                >
                                    {intl.formatMessage(messages.login)}
                                </Link>
                            )}

                            {!!customerInfo && (
                                <div className="d-flex align-items-center">
                                    <Image
                                        src={MailOutlineIconSrc}
                                        alt="mail icon"
                                        lazyLoad
                                        className="align-middle mr-0-5x"
                                    />
                                    <div className={styles.email}>{customerInfo.email}</div>
                                </div>
                            )}

                            {!customerInfo && (
                                <Link
                                    href="/login"
                                    variant={LinkVariant.Grey}
                                    internal
                                    className={classNames(styles.link, 'font-brand')}
                                    onClick={(event: MouseEvent) =>
                                        onUserClick(event, UserAction.Register)
                                    }
                                    dataTestId="register-link"
                                >
                                    {intl.formatMessage(messages.register)}
                                </Link>
                            )}
                        </div>

                        <Image
                            src={ChevronIconSrc}
                            alt="chevron icon"
                            lazyLoad
                            className={styles.chevronIcon}
                        />
                    </div>
                </div>
            </PureLink>

            <hr className="my-3x" />
        </div>
    );
};

export { VoucherModalHeader };
